@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.btn-default {
	@apply flex items-center text-white justify-center focus:outline-none  text-sm sm:text-base rounded-lg py-2 w-full;
}

.link-default {
	@apply inline-flex items-center font-bold text-altia-red hover:text-red-700 hover:underline;
}

.form-field {
	@apply text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400 group-[.isInvalid]:border-red-500 group-[.isInvalid]:text-red-500;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-size: 16px 12px;
	@apply bg-clip-padding bg-no-repeat;
}

.grecaptcha-badge {
	visibility: hidden;
}